// HOW TO: https://www.tiny.cloud/docs/tinymce/6/react-pm-bundle/

import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { Button } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { cx } from '@emotion/css'
import { ErrorNotice } from '@dis/components'
import { Control, useController } from 'react-hook-form'
import { DISALLOW_DND } from '@dis/constants'
import { purifyHtml } from '@dis/utils/src/purifyHtml'
import { Editor, IAllProps } from '@tinymce/tinymce-react'
import { EditorManager } from 'tinymce'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl, selectLanguage } from '@dis/redux/src/user/selectors'
import { isFirstLetterArabic } from '@dis/utils'
import { styles, getEditorContentStyle } from './styles'
import { submitByVirtualButton } from '../../utils'
import { EMPTY_VALUE } from './constants'

export type WysiwygProps = {
  autoFormSubmit?: boolean
  className?: string
  containerClassName?: string
  disabled?: boolean
  editorContainerClassName?: string
  formControl: Control<any>
  hasBorder?: boolean
  isFullWidth?: boolean
  isPlaceholderGray?: boolean
  isUnderLineHover?: boolean
  limit?: number
  name: string
  onBlur?: IAllProps['onBlur']
  onFocus?: IAllProps['onFocus']
  onSubmit?: VoidFunction
  placeholder?: string
}

// Translation keys can be found in the original translation file of tinymce: https://www.tiny.cloud/get-tiny/language-packages/
const dic = {
  Cancel: tKeys.common.cancel,
  Close: tKeys.common.close,
  'Insert/edit link': tKeys.wysiwyg.linkModal.title,
  'Open link in...': tKeys.wysiwyg.linkModal.openLinkIn.title,
  Save: tKeys.common.save,
  'Text to display': tKeys.wysiwyg.linkModal.urlName,
  Url: tKeys.wysiwyg.linkModal.url,
} as const

export const Wysiwyg = ({
  autoFormSubmit,
  className,
  containerClassName,
  disabled,
  editorContainerClassName,
  formControl,
  hasBorder,
  isFullWidth = false,
  isPlaceholderGray = false,
  isUnderLineHover = true,
  limit,
  name,
  onSubmit,
  onBlur: onBlurProp,
  onFocus: onFocusProp,
  placeholder = 'Placeholder',
}: WysiwygProps) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isPrerendered, setIsPrerendered] = useState(false)
  const [limitExceededValue, setLimitExceededValue] = useState<number>(0)

  const containerRef = useRef(null)
  const editorRef = useRef<Parameters<NonNullable<Editor['props']['onInit']>>[1] | null>(null)
  const isInternalModalOpened = useRef(false)

  const language = useAppSelector(selectLanguage)
  const isRtl = useAppSelector(selectIsLanguageRtl)

  const [isArabic, setIsArabic] = useState(isRtl)

  const { t } = useTranslation()

  const {
    field: { onBlur, onChange, value = '' },
  } = useController({ control: formControl, name })

  const fontFamily = window.getComputedStyle(document.body, null).fontFamily

  const setIsPrerenderedWrapper = useCallback(() => {
    setIsPrerendered(true)
  }, [])

  const onFocusWrapper = useCallback<NonNullable<IAllProps['onFocus']>>(
    (event, editor) => {
      onFocusProp?.(event, editor)
    },
    [onFocusProp],
  )

  const onBlurWrapper = useCallback<NonNullable<IAllProps['onBlur']>>(
    (event, editor) => {
      onBlur()
      onBlurProp?.(event, editor)
    },
    [onBlur, onBlurProp],
  )

  const onSubmitWrapper = useCallback(
    (event: Event) => {
      if (isEditMode) {
        const newEvent = {
          ...event,
          target: {
            ...event.target,
            value: purifyHtml(editorRef?.current?.getContent() || ''),
          },
        }

        onBlur()
        onChange(newEvent)
        onSubmit?.()
        setIsEditMode(false)

        if (autoFormSubmit && containerRef.current) {
          submitByVirtualButton(containerRef.current)
        }
      }
    },
    [autoFormSubmit, isEditMode, onBlur, onChange, onSubmit],
  )

  const onOpenClick = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
    if (event.target instanceof HTMLAnchorElement && event.target.href) {
      event.stopPropagation()
      return false
    }

    setIsEditMode(true)

    setTimeout(() => {
      if (editorRef.current) {
        editorRef.current?.focus()
      }
    }, 50)

    return true
  }, [])

  const onSubmitButtonClick = useCallback(() => {
    const event = new Event('click')
    onSubmitWrapper(event)
  }, [onSubmitWrapper])

  const onCloseButtonClick = useCallback(() => {
    setIsEditMode(false)
  }, [])

  const onClickAway = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!isInternalModalOpened.current) {
        if (!limitExceededValue) {
          onSubmitWrapper(event)
        } else {
          onBlur()
          setIsEditMode(false)
          editorRef.current?.setContent(value)
        }
      }
    },
    [limitExceededValue, onBlur, onSubmitWrapper, value],
  )

  const processCharacterCount = useCallback(() => {
    if (editorRef.current?.plugins.wordcount && limit) {
      editorRef.current.plugins.wordcount.body.get

      const count = editorRef.current.plugins.wordcount.body.getCharacterCount()
      setLimitExceededValue(count > limit ? count : 0)
    }
  }, [limit])

  const onInit = useCallback<NonNullable<Editor['props']['onInit']>>(
    (event, editor) => {
      editorRef.current = editor

      // Must be covered in setTimeout
      setTimeout(() => {
        editor.setContent(value)

        processCharacterCount()
      })
    },
    [processCharacterCount, value],
  )

  const onEditorChange = useCallback<NonNullable<IAllProps['onEditorChange']>>(() => {
    processCharacterCount()

    const value = (editorRef?.current?.getContent({ format: 'text' }) || '').trim()

    if (value.length) {
      const result = isFirstLetterArabic(value)

      setIsArabic(result)

      if (editorRef?.current) {
        editorRef.current.getBody().dir = result ? 'rtl' : 'ltr'
      }
    } else {
      setIsArabic(isRtl)

      if (editorRef?.current) {
        editorRef.current.getBody().dir = isRtl ? 'rtl' : 'ltr'
      }
    }
  }, [isRtl, processCharacterCount])

  const tinymce = (window as Record<string, any>).tinymce

  useEffect(() => {
    if (tinymce) {
      const editorManager = tinymce.EditorManager as EditorManager

      const translations: Record<string, string> = {}

      Object.entries(dic).forEach(([key, tKey]) => {
        translations[key] = t(tKey)
      })

      editorManager.addI18n(language, translations)
    }
  }, [language, tinymce, t])

  useEffect(() => {
    if (!isEditMode && editorRef.current) {
      editorRef.current?.setContent(value)
    }
  }, [isEditMode, value])

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        className={cx(
          styles.container.root,
          {
            [DISALLOW_DND]: isEditMode,
            [styles.container.fullWidth]: isFullWidth,
          },
          containerClassName,
        )}
        ref={containerRef}>
        {!disabled && isPrerendered && (
          <div
            className={cx(
              styles.editorOuterContainer.root,
              {
                [styles.editorOuterContainer.visible]: isEditMode,
              },
              editorContainerClassName,
            )}>
            <div className={styles.editorOuterContainer.editorContainer.root}>
              {/* "Key" prop with language value to re-render Editor when language is changed */}
              <Editor
                init={{
                  autoresize_bottom_margin: 0,
                  content_css: false,
                  content_style: getEditorContentStyle(fontFamily),
                  directionality: isArabic ? 'rtl' : 'ltr',
                  language,
                  link_assume_external_targets: true,
                  link_context_toolbar: false,
                  link_default_target: '_blank',
                  link_target_list: [
                    { text: t(tKeys.wysiwyg.linkModal.openLinkIn.newWindow), value: '_blank' },
                  ],
                  link_title: false,
                  menubar: false,
                  plugins: 'lists link wordcount autoresize',
                  resize: false,
                  setup: (editor) => {
                    editor.on('OpenWindow', () => {
                      isInternalModalOpened.current = true
                    })

                    editor.on('CloseWindow', () => {
                      isInternalModalOpened.current = false
                    })

                    setTimeout(() => {
                      Object.values(editor.ui.registry.getAll().buttons).forEach((button) => {
                        button.tooltip = ''
                      })
                    }, 50)
                  },
                  skin: false,
                  statusbar: false,
                  toolbar: 'bold italic underline strikethrough | numlist bullist | link unlink',
                  toolbar_mode: 'sliding',
                }}
                key={language}
                onInit={onInit}
                onEditorChange={onEditorChange}
                onFocus={onFocusWrapper}
                onBlur={onBlurWrapper}
                test-data-id="tinymce-editor"
              />
              {!!limitExceededValue && (
                <ErrorNotice
                  className={styles.editorOuterContainer.editorContainer.error}
                  error={{
                    message: t(tKeys.wysiwyg.maxCharError, { count: limitExceededValue, limit }),
                    name: tKeys.wysiwyg.maxCharError,
                  }}
                />
              )}
            </div>
            <div className={styles.actionButtons}>
              <Button
                variant="contained"
                color="primary"
                disabled={!!limitExceededValue}
                onClick={onSubmitButtonClick}>
                {t(tKeys.common.save)}
              </Button>
              <Button variant="contained" color="secondary" onClick={onCloseButtonClick}>
                {t(tKeys.common.cancel)}
              </Button>
            </div>
          </div>
        )}

        {!isEditMode && (
          <div
            onClick={disabled ? undefined : onOpenClick}
            onMouseEnter={setIsPrerenderedWrapper}
            className={cx(
              {
                [styles.view.placeholderGray]:
                  (value === EMPTY_VALUE || value === '') && isPlaceholderGray,
                [styles.view.border]: hasBorder,
                [styles.view.underlineHover]: isUnderLineHover,
              },
              className,
              DISALLOW_DND,
            )}
            dangerouslySetInnerHTML={{
              __html: purifyHtml(
                value?.replace(/(<([^>]+)>)/g, '').replace(/\s/g, '').length ? value : placeholder,
              ),
            }}
          />
        )}
      </div>
    </ClickAwayListener>
  )
}
