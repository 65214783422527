import { useCallback, useEffect, useState, ReactNode, Dispatch, SetStateAction } from 'react'
import { FormGroup, FormLabel, TextField, TextFieldProps, Box, FormGroupProps } from '@mui/material'
import { FieldError } from 'react-hook-form'
import { cx } from '@emotion/css'
import {
  TypographyKeys,
  getTypographyProp,
  TypographyFontWeight,
  typographyFontWeight,
  ParagraphVariant,
  typography,
} from '@dis/styles/src/theme'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { isFirstLetterArabic } from '@dis/utils'
import { ErrorNotice } from '../ErrorNotice/ErrorNotice'
import { styles } from './styles'
import { VisibleLimit } from './VisibleLimit'
import { unsanitize } from '../utils'

export type InputProps = Omit<TextFieldProps, 'value' | 'name' | 'textAlign' | 'error'> & {
  bold?: boolean
  containerSx?: FormGroupProps['sx']
  delay?: number
  disabledColor?: string
  error?: FieldError
  fixedLabel?: boolean
  fixedLabelVariant?: ParagraphVariant
  fontColor?: string
  fontSize?: TypographyKeys
  fontWeight?: TypographyFontWeight
  hoverUnderline?: boolean
  icon?: ReactNode
  isFocused: boolean
  isLimitVisible?: boolean
  maxLength?: number
  placeholderColor?: string
  sameStyleForDisabled?: boolean
  setIsFocused: Dispatch<SetStateAction<boolean>>
  showLimitOnTop?: boolean
  startIcon?: ReactNode
  textAlign?: 'start' | 'center' | 'end'
  underline?: boolean
  value?: string
  visibleLimitClassName?: string
  width?: string
}

export const Input = ({
  width = '100%',
  className,
  containerSx,
  fixedLabelVariant = 'body',
  fontWeight = 'regular',
  textAlign = 'start',
  fontSize = 'paragraph.medium',
  fontColor = '',
  isFocused,
  underline = false,
  error,
  hoverUnderline = true,
  color = 'primary',
  variant = 'standard',
  fixedLabel,
  label,
  multiline,
  isLimitVisible = false,
  visibleLimitClassName,
  sx,
  showLimitOnTop,
  value,
  onChange: onChangeProp,
  onKeyUp: onKeyUpProps,
  onBlur: onBlurProp,
  onKeyDown: onKeyDownProp,
  onFocus: onFocusProp,
  placeholderColor = '',
  delay,
  sameStyleForDisabled,
  disabled,
  startIcon,
  maxLength,
  icon,
  disabledColor,
  setIsFocused,
  ...restProps
}: InputProps) => {
  const [isGhostShown, setIsGhostShown] = useState(!!delay)
  const [charactersCount, setCharactersCount] = useState(0)

  const isRtlLanguage = useAppSelector(selectIsLanguageRtl)

  const [isArabic, setIsArabic] = useState(isRtlLanguage)

  const isLengthEqualsCharacters = charactersCount >= (maxLength || 0)

  const unsanitizedValue = value ? unsanitize(value) : value

  useEffect(() => {
    if (delay) {
      const ref = setTimeout(() => {
        setIsGhostShown(false)
      }, delay)

      return () => {
        clearTimeout(ref)
      }
    }
  }, [setIsGhostShown, delay])

  const onChangeWrapper = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      const value = e.target.value

      if (value.length) {
        const result = isFirstLetterArabic(value)

        setIsArabic(result)
      } else {
        setIsArabic(isRtlLanguage)
      }

      onChangeProp?.(e)
      setCharactersCount(e.target.value.length)
    },
    [isRtlLanguage, onChangeProp, setIsArabic],
  )

  const onKeyUpWrapper = useCallback<NonNullable<TextFieldProps['onKeyUp']>>(
    (e) => {
      const isEnter = e.key === 'Enter'

      if (!multiline && isEnter) {
        e.preventDefault()
        e.stopPropagation()

        const target = e.target as HTMLElement

        target?.blur()
      }

      onKeyUpProps?.(e)

      if (!multiline && isEnter) {
        return false
      }
    },
    [onKeyUpProps, multiline],
  )

  // Prevent form auto-submitting when enter
  const onKeyDownWrapper = useCallback<NonNullable<TextFieldProps['onKeyDown']>>(
    (e) => {
      onKeyDownProp?.(e)

      if (!multiline && e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        return false
      }
    },
    [multiline, onKeyDownProp],
  )

  const onFocusWrapper = useCallback<NonNullable<TextFieldProps['onFocus']>>(
    (e) => {
      onFocusProp?.(e)
      setCharactersCount(e.target.value.length)
      setIsFocused?.(true)
    },
    [onFocusProp, setIsFocused],
  )

  const directStyles: Record<string, string | number> = {
    direction: isArabic ? 'rtl' : 'ltr',
    fontSize: getTypographyProp(fontSize, 'fontSize'),
    fontWeight: typographyFontWeight[fontWeight],
    textAlign,
  }

  if (fontColor) {
    directStyles.color = fontColor
  }

  if (placeholderColor) {
    directStyles['&::placeholder'] = { color: placeholderColor } as any
  }

  const classes = cx(className, {
    [styles.noUnderline]: !underline,
    [styles.sameStyleForDisabled]: sameStyleForDisabled && disabled,
  })

  const disabledSx = {
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: disabledColor,
    },
  }

  const fixedLabelCss = typography.paragraph[fixedLabelVariant]

  return (
    <FormGroup
      className={cx(styles.inputForm, {
        [styles.placeholderDisappearAndHoverUnderline]: hoverUnderline,
      })}
      sx={containerSx}>
      {!!label && fixedLabel && (
        <FormLabel required={restProps.required} sx={[fixedLabelCss, { mb: 1 }]}>
          {label}
        </FormLabel>
      )}
      {isGhostShown ? (
        <Box className={cx(styles.ghost, classes)} sx={{ ...sx, width }}>
          {unsanitizedValue}
        </Box>
      ) : (
        <TextField
          {...restProps}
          error={!!error}
          disabled={disabled}
          InputProps={{
            className: classes,
            endAdornment: icon,
            startAdornment: startIcon,
            sx: directStyles,
          }}
          inputProps={{ className: classes, maxLength, sx: directStyles }}
          size="medium"
          className={classes}
          label={fixedLabel || !label ? undefined : label}
          onChange={onChangeWrapper}
          onKeyUp={onKeyUpWrapper}
          onKeyDown={onKeyDownWrapper}
          value={unsanitizedValue ?? ''}
          color={color}
          variant={variant}
          multiline={multiline}
          onFocus={onFocusWrapper}
          onBlur={onBlurProp}
          sx={{
            ...sx,
            ...disabledSx,
            width,
          }}
        />
      )}
      <ErrorNotice error={error} />
      <VisibleLimit
        isLimitVisible={isLimitVisible}
        maxLength={maxLength}
        charactersCount={charactersCount}
        isFocused={isFocused}
        isLengthEqualsCharacters={isLengthEqualsCharacters}
        showLimitOnTop={showLimitOnTop}
        visibleLimitClassName={visibleLimitClassName}
      />
    </FormGroup>
  )
}
