export const APP_DOMAIN = 'https://kpmgdiscovery.com'

export const BE_REST_API_URL = '/api/eventhandler'

export const MB_IN_B = 1024 * 1024

export const MAX_FILE_SIZE_B = 5 * MB_IN_B

export const MAX_COST_VALUE = 999_999_999_999_999

// It is used for disabling dnd over the element this class is passed to.
export const DISALLOW_DND = 'disallow_dnd_reserved_class'

export const GRID_WRAPPER_ID = 'discovery-grid-wrapper'

export const INPUT_MIN_CHARACTERS = 3

/**
 * Universal string length for names
 */
export const MAX_NAME_LENGTH = 300

/**
 * Universal string length, e.g. description
 */
export const MAX_STRING_LENGTH = 1_000

/**
 * Basic font-size for REM units.
 * To change this value globally it's necessary to set a new value here and also in the index.scss.
 */
export const BASIC_FONT_SIZE = 16 // px

/**
 * string length, e.g. tenant description
 */
export const MAX_TENANT_DESCRIPTION = 130

/**
 * string length, e.g. tenant name
 */
export const MAX_TENANT_NAME = 30

export const MAX_COMMENT_TEXT = 500

export const MAX_CATEGORY_NAME = 15

export const navBarLink =
  'https://spo-global.kpmg.com/sites/GO-OI-SVC-advisory-tools/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGO%2DOI%2DSVC%2Dadvisory%2Dtools%2FShared%20Documents%2FKPMG%20Discovery%20Materials%2FDiscoveryUserManual%20v2%2E2%2Epdf&parent=%2Fsites%2FGO%2DOI%2DSVC%2Dadvisory%2Dtools%2FShared%20Documents%2FKPMG%20Discovery%20Materials'

/** *
 * https://learn.microsoft.com/en-us/dotnet/api/system.decimal.maxvalue?view=net-8.0#remarks
 */
// eslint-disable-next-line no-loss-of-precision
export const MAXIMUM_POSSIBLE_NUMERIC_VALUE = 79_228_162_514_264_337_593_543_950_335

export const JOURNEY_ACTIVE_USERS_PREVIEW_LENGTH = 10
