import { useCallback } from 'react'
import { Button, ButtonProps } from '@mui/material'
import FolderIconRound from '@dis/assets/src/icons/FolderIconRound.svg'
import FolderIcon from '@dis/assets/src/icons/FolderIcon.svg'
import PlusIcon from '@dis/assets/src/icons/PlusIcon.svg'
import PlusIconRound from '@dis/assets/src/icons/PlusIconRound.svg'
import SyncIcon from '@dis/assets/src/icons/SyncIcon.svg'
import EditIcon from '@dis/assets/src/icons/EditIcon.svg'
import PdfIcon from '@dis/assets/src/icons/PdfIcon.svg'
import XlsIcon from '@dis/assets/src/icons/XlsIcon.svg'
import { cx, TypographyKeys, getTypographyProp } from '@dis/styles'
import { styles } from './styles'
import { SvgImage } from '../SvgImage/SvgImage'

export type TextIconButtonProps = ButtonProps & {
  fontSize?: TypographyKeys
  iconSize?: TypographyKeys
  iconVariant?:
    | 'add'
    | 'addRound'
    | 'folder'
    | 'folderRound'
    | 'edit'
    | 'save'
    | 'sync'
    | 'cancel'
    | 'pdf'
    | 'xls'
  onClick?: VoidFunction
  text?: string
}

const iconMap: Record<NonNullable<TextIconButtonProps['iconVariant']>, string> = {
  add: PlusIcon,
  addRound: PlusIconRound,
  cancel: '',
  edit: EditIcon,
  folder: FolderIcon,
  folderRound: FolderIconRound,
  pdf: PdfIcon,
  save: '',
  sync: SyncIcon,
  xls: XlsIcon,
}

export const TextIconButton = ({
  className,
  color = 'primary',
  disabled,
  fontSize = 'paragraph.body',
  iconSize,
  iconVariant,
  onClick,
  text,
  type = 'button',
  variant = 'contained',
  ...restProps
}: TextIconButtonProps) => {
  const onClickWrapper = useCallback(() => {
    if (!disabled && onClick) {
      onClick()
    }
  }, [disabled, onClick])

  const icon = iconVariant ? iconMap[iconVariant] : ''

  return (
    <Button
      {...restProps}
      className={cx(styles.root(getTypographyProp(fontSize, 'fontSize')), className)}
      color={color}
      type={type}
      disabled={disabled}
      data-testid="text-icon-button"
      startIcon={
        !!icon && (
          <SvgImage
            src={icon}
            className={styles.svgImage(iconSize ? getTypographyProp(iconSize, 'fontSize') : '')}
          />
        )
      }
      onClick={onClickWrapper}
      variant={variant}>
      {text}
    </Button>
  )
}
