import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Language } from '@dis/types/src/GeneralTypes'
import { LANGUAGES } from '@dis/constants'

type UserReducerState = {
  email?: string
  language: Language
  name: string
}

const initialState: UserReducerState = {
  email: undefined,
  language: LANGUAGES.en,
  name: '',
}

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setUserEmail: (state: UserReducerState, action: PayloadAction<string | undefined>) => {
      state.email = action.payload
    },
    setUserLanguage: (state: UserReducerState, action: PayloadAction<Language>) => {
      state.language = action.payload
    },
    setUserName: (state: UserReducerState, action: PayloadAction<string>) => {
      state.name = action.payload
    },
  },
})
