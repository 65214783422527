import { css } from '@emotion/css'
import { colors, shadowClasses, theme, typography, typographyFontWeight } from '@dis/styles'

export const styles = {
  bubble: css`
    border-radius: 100%;
    padding: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    min-width: 0;
    background-color: ${colors.kpmg.cobaltLight};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    border-color: ${colors.kpmg.cobaltLight};
    color: ${colors.kpmg.pacificBlue};
    font-weight: ${typographyFontWeight.bold};
    font-size: ${typography.paragraph.body.fontSize};
    line-height: ${typography.paragraph.body.lineHeight};
  `,
  list: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    overflow: auto;
    max-height: 20rem;
  `,
  listItem: {
    leftColumn: css`
      display: flex;
      align-self: center;
      justify-content: center;
    `,
    rightColumn: css`
      display: flex;
      flex-direction: column;

      & > * {
        width: 100%;
        text-align: start;
      }
    `,
    root: css`
      display: flex;
      gap: ${theme.spacing(2)};
      padding: ${theme.spacing(3)};

      & > * {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  },
  mainButton: css`
    border-radius: 100%;
    padding: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    min-width: 0;
    cursor: pointer;
    font-weight: normal;
  `,
  popover: {
    root: css`
      .MuiPopover-paper {
        transform: translateX(0.5rem) translateY(0.7rem) !important;
        overflow-x: unset;
        overflow-y: unset;
        border-radius: 0.5rem;

        :before {
          position: absolute;
          content: ' ';
          inset-inline-end: ${theme.spacing(4)};
          top: calc(-0.5rem + 1px);
          display: block;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          border-bottom: 0.5rem solid ${colors.background.white};
        }
      }
    `,
    rtl: css`
      .MuiPopover-paper {
        transform: translateX(-0.5rem) translateY(0.7rem) !important;
      }
    `,
  },
  root: css`
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      margin-inline-start: -${theme.spacing(1)};
    }
  `,
  tooltip: css`
    .MuiTooltip-tooltip {
      ${shadowClasses[18]};
      background-color: ${colors.background.white};
      color: ${colors.text};
      padding: ${theme.spacing(3)};
      border-radius: 0.5rem;
    }

    .MuiTooltip-arrow {
      color: ${colors.background.white};
    }
  `,
}
