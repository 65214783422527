import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Comments } from '@dis/types/src/CommentsTypes'

type CommentsReducerState = {
  threads?: Comments
}

const initialState: CommentsReducerState = {
  threads: undefined,
}

export const commentsSlice = createSlice({
  initialState,
  name: 'comments',
  reducers: {
    setCommentsThreads: (state: CommentsReducerState, action: PayloadAction<Comments>) => {
      state.threads = action.payload
    },
  },
})
