import { RootState } from '@dis/redux'
import { Info, JourneyData, Persona, Threads } from '@dis/modules/src/journey/grid/types'

export const selectSelectedJourneyId = (state: RootState) => {
  return state.journeys.selectedJourneyId
}

// Notice: Keep explicit return type definition
export const selectSelectedJourneyName = (state: RootState): string =>
  state.journeys.journeyData?.subscribe?.info?.name || state.journeys.selectedJourneyName || ''

// Notice: Keep explicit return type definition
export const selectSelectedJourneyVersion = (state: RootState): number | undefined =>
  state.journeys.journeyData?.version

export const selectJourneysStructure = (state: RootState) => state.journeys.journeysStructure

// Notice: Keep explicit return type definition
export const selectJourneysData = (state: RootState): JourneyData | undefined => {
  if (state.journeys.journeyData) {
    return state.journeys.journeyData
  }

  return undefined
}

export const selectJourneyDataPrepared = (state: RootState) =>
  !!state.journeys.journeysStructure && !!state.journeys.journeyData

export const selectJourneySidebar = (state: RootState) =>
  state.journeys.journeysStructure?.sidebar || []

export const selectJourneyLayersStructure = (state: RootState) =>
  state.journeys.journeysStructure?.layersStructure || []

// Notice: Keep explicit return type definition
export const selectJourneyInitialLoad = (state: RootState): JourneyData | undefined => {
  if (state.journeys.journeyData) {
    return state.journeys.journeyData
  }

  return {
    sideloads: [],
    subscribe: {},
    version: 0,
  }
}

export const selectJourneyGuidIndexMap = (state: RootState) =>
  state.journeys.journeysStructure?.guidIndexMap || {}

export const selectJourneyHeaderStructure = (state: RootState) =>
  state.journeys.journeysStructure?.headerStructure || []

export const selectJourneyHeaderModel = (state: RootState) =>
  state.journeys.journeysStructure?.headerModel || []

export const selectJourneyGuidColorMap = (state: RootState) =>
  state.journeys.journeysStructure?.guidColorMap || {}

export const selectJourneyWsError = (state: RootState) => state.journeys.wsError

// Notice: Keep explicit return type definition
export const selectJourneyInfo = (state: RootState): Info | undefined =>
  state.journeys.journeyData?.subscribe?.info

// Notice: Keep explicit return type definition
export const selectJourneyPersonas = (state: RootState): Persona[] | undefined =>
  state.journeys.journeyData?.subscribe?.personas

export const selectJourneyThreads = (state: RootState): Record<string, Threads> | undefined =>
  state.journeys.journeyData?.subscribe?.threads

export const selectJourneyLockedLayers = (state: RootState) => state.journeys.lockedLayers

export const selectJourneyCanEdit = (state: RootState) => state.journeys.canEdit

export const selectJourneyTenantLimits = (state: RootState) => state.journeys.tenantLimits

export const selectJourneyChannels = (state: RootState) => state.journeys.channels

export const selectJourneyCapabilities = (state: RootState) => state.journeys.capabilities

export const selectJourneyUserStories = (state: RootState) => state.journeys.userStories

export const selectJourneyTouchpointsLength = (state: RootState) =>
  state.journeys.journeyData?.subscribe?.touchpoints?.length || 0

export const selectChannelsEditLayerId = (state: RootState) => state.journeys.channelsEditLayerId

export const selectChannelsCodeTypeMap = (state: RootState) => state.journeys.channelsCodeTypeMap

export const selectJourneyIsManagementOpen = (state: RootState) => state.journeys.isManagementOpen

export const selectIsChangeRunning = (state: RootState) => state.journeys.isChangeRunning

export const selectJourneySignature = (state: RootState) => state.journeys.journeySignature

export const selectIsJourneyBackEnable = (state: RootState) => state.journeys.isBackEnable

export const selectIsJourneyForwardEnable = (state: RootState) => state.journeys.isForwardEnable

export const selectIsHistoryVersionPreview = (state: RootState) =>
  state.journeys.isHistoryVersionPreview

export const selectForceRefetchFlag = (state: RootState) => state.journeys.forceRefetchFlag
