import { ReactNode, FC, useCallback } from 'react'
import { Grid, ButtonProps } from '@mui/material'
import { Header, TextIconButton, TextIconButtonProps } from '@dis/components'
import { PopoverSort } from '@dis/modules/src/PopoverSortFilter/PopoverSort'
import { CreatedUpdatedTitleSortType } from '@dis/types/src/PopoverFilterSortTypes'
import { colors, cx } from '@dis/styles'
import { styles } from './styles'

type IconVariant = NonNullable<TextIconButtonProps['iconVariant']>

type ButtonsProps = {
  color?: 'primary' | 'secondary'
  disabled?: boolean
  iconVariant: IconVariant
  onClick: VoidFunction | (() => Promise<void>)
  text: string
  variant?: 'text' | 'outlined' | 'contained'
}

export interface ScreenProps {
  button?: ButtonsProps
  children: ReactNode
  isSort?: boolean
  secondButton?: ButtonsProps
  setSortBy?: React.Dispatch<React.SetStateAction<CreatedUpdatedTitleSortType | undefined>>
  sortBy?: CreatedUpdatedTitleSortType
  testId?: string
  title?: string
  width?: 'auto' | 'normal'
}

const iconVariants: Partial<Record<IconVariant, ButtonProps['variant']>> = {
  addRound: 'text',
  edit: 'outlined',
  pdf: 'outlined',
  xls: 'outlined',
}

export const Screen: FC<ScreenProps> = ({
  children,
  title,
  button,
  secondButton,
  sortBy,
  setSortBy = () => {},
  isSort,
  testId = '',
  width = 'normal',
}) => {
  const getVariant = useCallback(
    (b: ButtonsProps) => b.variant || iconVariants[b.iconVariant] || 'contained',
    [],
  )

  return (
    <div
      className={cx(styles.container, { [styles.normalWidth]: width === 'normal' })}
      data-testid={testId}>
      {title && (
        <Grid container justifyContent="space-between" pb={6}>
          <Header variant="h1" color={colors.text}>
            {title}
          </Header>
          <div
            className={cx(styles.buttonsContainer, {
              [styles.secondaryBtn]: !!secondButton,
            })}>
            {isSort && <PopoverSort sortBy={sortBy} setSortBy={setSortBy} />}
            {secondButton && (
              <TextIconButton
                variant={getVariant(secondButton)}
                color={secondButton.color || 'secondary'}
                iconVariant={secondButton.iconVariant}
                onClick={secondButton.onClick}
                disabled={secondButton.disabled}
                text={secondButton.text}
              />
            )}
            {button && (
              <TextIconButton
                variant={getVariant(button)}
                color={button.color || 'primary'}
                iconVariant={button.iconVariant}
                onClick={button.onClick}
                disabled={button.disabled}
                text={button.text}
              />
            )}
          </div>
        </Grid>
      )}
      {children}
    </div>
  )
}
