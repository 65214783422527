import { useCallback, useMemo, useState } from 'react'
import HamburgerMenuIcon from '@dis/assets/src/icons/HamburgerMenuIcon.svg'
import { Paragraph, PopoverWithAnchor } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import {
  selectApplicationMenuPermissions,
  selectIsArabicLanguageAllowed,
  selectIsFeatureConnectedCapabilitiesAllowed,
  selectUserRole,
} from '@dis/redux/src/security/selectors'
import { selectSelectedIsTemplateTenant } from '@dis/redux/src/tenants/tenantsSelectors'
import { LANGUAGES } from '@dis/constants'
import { useNavTo } from '@dis/hooks'
import { useSendEmail } from '@dis/hooks/src/useSendEmail'
import { useShareJourney } from '@dis/hooks/src/useShareJourney'
import { useExportPDF } from '@dis/hooks/src/useExportPDF'
import { useChangeLanguage } from '@dis/hooks/src/useChange'
import { useParams } from 'react-router-dom'
import { useRouteMatch } from '@dis/hooks/src/useRouteMatch'
import { Language, LoadingInfoType } from '@dis/types/src/GeneralTypes'
import { get } from 'lodash'
import { useCentralModalLoader } from '@dis/hooks/src/useCentralModalLoader'
import { useErrorCodeModal } from '@dis/hooks/src/useErrorCodeModal'
import { colors } from '@dis/styles'
import { selectUserName } from '@dis/redux/src/user/selectors'
import { getClearName, getSignature } from '@dis/utils/src/users'
import { getMenuData, MenuDataItem } from './constants'
import { SubmenuDataItem } from './types'
import { styles } from './styles'

const loadingInfoDefault: LoadingInfoType = {
  fileName: undefined,
  isImport: undefined,
  isLoading: false,
  status: undefined,
  translation: undefined,
}

export const ApplicationMenu = () => {
  const [loadingInfo, setLoadingInfo] = useState<LoadingInfoType>(loadingInfoDefault)

  const isFeatureConnectedCapabilitiesAllowed = useAppSelector(
    selectIsFeatureConnectedCapabilitiesAllowed,
  )
  const isArabicLanguageAllowed = useAppSelector(selectIsArabicLanguageAllowed)
  const menuPermissions = useAppSelector(selectApplicationMenuPermissions)
  const isTemplateTenant = useAppSelector(selectSelectedIsTemplateTenant)
  const userRole = useAppSelector(selectUserRole)
  const userName = useAppSelector(selectUserName)

  const { t } = useTranslation()
  const { navigate } = useNavTo()

  const { send: changeLanguage } = useChangeLanguage()
  const { handleSendEmail } = useSendEmail()
  const { handleShareJourney } = useShareJourney()
  const { handleExportPDF } = useExportPDF({ setLoadingInfo })

  const { tenantId, atlasId, folderId, journeyId } = useParams()

  const { isJourney, isJourneyFolder, isPersona } = useRouteMatch()

  const atlas = atlasId ? parseInt(atlasId) : undefined
  const folder = folderId ? parseInt(folderId) : undefined
  const journey = journeyId ? parseInt(journeyId) : undefined
  const isNotJourney = !isJourney && !isJourneyFolder

  const handleCloseError = useCallback(() => {
    setLoadingInfo(loadingInfoDefault)
  }, [])

  const onMenuItemClick = useCallback(
    ({
      type,
      parentType,
      navigateTo,
      value,
    }: {
      navigateTo: MenuDataItem['navigateTo'] | SubmenuDataItem['navigateTo']
      parentType: MenuDataItem['type'] | SubmenuDataItem['type'] | undefined
      type: MenuDataItem['type'] | SubmenuDataItem['type']
      value: SubmenuDataItem['type'] | undefined
    }) =>
      () => {
        if (navigateTo) {
          navigate(navigateTo)
        }

        if (parentType === 'language' && value) {
          changeLanguage(value as Language)
          return
        }

        switch (type) {
          case 'sendEmail':
            handleSendEmail()
            break
          case 'logout':
            dispatchedActions.security.logoutThisDevice()
            break
          case 'logoutAllDevices':
            dispatchedActions.security.logoutAllDevices()
            break
          case 'shareJourney':
            handleShareJourney(atlas, folder, journey)()
            break
          case 'exportToPdf':
            handleExportPDF()
            break
          default:
            break
        }
      },

    [
      changeLanguage,
      navigate,
      handleSendEmail,
      handleShareJourney,
      handleExportPDF,
      atlas,
      folder,
      journey,
    ],
  )

  const filteredMenuData = useMemo(() => {
    const filterFnc = ({
      type,
      parentType,
      navigateTo,
      requireTenant,
      value,
    }: {
      navigateTo: MenuDataItem['navigateTo'] | SubmenuDataItem['navigateTo']
      parentType: MenuDataItem['type'] | SubmenuDataItem['type'] | undefined
      requireTenant: boolean
      type: MenuDataItem['type'] | SubmenuDataItem['type']
      value: SubmenuDataItem['type'] | undefined
    }) => {
      if (requireTenant && !tenantId) {
        return false
      }

      if (parentType === 'language') {
        if (value === LANGUAGES.ar) return isArabicLanguageAllowed
      }

      if (navigateTo === 'capabilities' && !isFeatureConnectedCapabilitiesAllowed) return false

      if (navigateTo === 'capabilityManagement' && !isFeatureConnectedCapabilitiesAllowed)
        return false

      // Don't show global capability management item if app is inside a tenant
      if (navigateTo === 'globalCapabilityManagement' && !!tenantId) return false

      // Don't show global user management item if app is inside a tenant
      if (navigateTo === 'globalUserManagement' && !!tenantId) return false

      // Don't show global template management item if app is inside a tenant
      if (navigateTo === 'globalTemplateManagement' && !!tenantId) return false

      if (navigateTo === 'templateManagement' && isTemplateTenant && userRole !== 'kpmgadmin')
        return false

      if (
        (isNotJourney && type === 'sendEmail') ||
        (isNotJourney && type === 'shareJourney') ||
        (!isPersona && type === 'exportToPdf')
      )
        return false

      if (parentType) {
        return get(menuPermissions, `${parentType}.${type}`)
      }

      return get(menuPermissions, type)
    }

    return (
      getMenuData().filter((item) => {
        if (item.items?.length) {
          item.items = item.items.filter(({ type, navigateTo, value, requireTenant }) =>
            filterFnc({
              navigateTo,
              parentType: item.type,
              requireTenant,
              type,
              value,
            }),
          )
        }

        return filterFnc({
          navigateTo: item.navigateTo,
          parentType: undefined,
          requireTenant: item.requireTenant,
          type: item.type,
          value: undefined,
        })
      }) || []
    )
  }, [
    tenantId,
    isPersona,
    isTemplateTenant,
    isArabicLanguageAllowed,
    isFeatureConnectedCapabilitiesAllowed,
    isNotJourney,
    menuPermissions,
    userRole,
  ])

  const popoverItems = useMemo(() => {
    return filteredMenuData.map((item) => {
      item.onClick = onMenuItemClick({
        navigateTo: item.navigateTo,
        parentType: undefined,
        type: item.type,
        value: undefined,
      })

      return {
        icon: item.icon,
        items: item.items?.map((subItem) => {
          subItem.onClick = onMenuItemClick({
            navigateTo: subItem.navigateTo,
            parentType: item.type,
            type: subItem.type,
            value: subItem.value,
          })

          return { icon: subItem.icon, label: t(subItem.title), onClick: subItem.onClick }
        }),
        label: t(item.title),
        onClick: item.onClick,
        suffix: item.type === 'language' ? t(tKeys.applicationMenu.languages) : undefined,
      }
    })
  }, [filteredMenuData, onMenuItemClick, t])

  useCentralModalLoader(loadingInfo.isLoading, loadingInfo.translation)

  useErrorCodeModal({
    defaultContent: t(tKeys.errorModal.journeys.description.export, { name: loadingInfo.status }),
    errorCode: loadingInfo.status && loadingInfo.status !== 200 ? 'DEFAULT_ERROR' : undefined,
    onCrossBtnClick: handleCloseError,
    onPrimaryBtnClick: handleCloseError,
    primaryBtnText: t(tKeys.common.accept),
    title: t(tKeys.errorModal.error),
  })

  return (
    <PopoverWithAnchor
      header={
        userName && userRole ? (
          <div className={styles.meInfoContainer}>
            <div className={styles.leftColumn}>
              <div className={styles.bubble}>{getSignature(userName)}</div>
            </div>
            <div className={styles.rightColumn}>
              <div>
                <Paragraph variant="small" fontWeight="bold">
                  {getClearName(userName)}
                </Paragraph>
                <Paragraph variant="tiny" fontWeight="regular" color={colors.gray.gray40}>
                  {!!userRole && t(tKeys.userRoles[userRole])}
                </Paragraph>
              </div>
            </div>
          </div>
        ) : undefined
      }
      icon={HamburgerMenuIcon}
      iconSize="header.h1"
      iconColor={colors.gray.gray5}
      items={popoverItems}
      submenuOnLeft
      menuOnLeft
    />
  )
}
