/**
 * @generated SignedSource<<4b10f1585d492c2dc4859fb7f4970fab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type JourneyCommentStatus = "none" | "opened" | "resolved" | "%future added value";
export type JourneyResourceType = "cell" | "layer" | "%future added value";
export type JourneyCommentListQueryInput = {
  journeyId: number;
  tenantId: number;
  threadId: string;
};
export type QueriesGetJourneyCommentListQuery$variables = {
  input: JourneyCommentListQueryInput;
};
export type QueriesGetJourneyCommentListQuery$data = {
  readonly journeyCommentList: {
    readonly items: ReadonlyArray<{
      readonly content: string;
      readonly created: string;
      readonly id: string;
      readonly metadata: {
        readonly userInfo: {
          readonly email: string;
          readonly name: string;
          readonly oid: string;
        };
      };
      readonly resourceId: string;
      readonly resourceType: JourneyResourceType;
      readonly status: JourneyCommentStatus;
      readonly threadCommentId: string | null | undefined;
      readonly updated: string | null | undefined;
    }>;
  };
};
export type QueriesGetJourneyCommentListQuery = {
  response: QueriesGetJourneyCommentListQuery$data;
  variables: QueriesGetJourneyCommentListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "JourneyCommentListQueryResult",
    "kind": "LinkedField",
    "name": "journeyCommentList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JourneyCommentDto",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JourneyCommentMetadataDto",
            "kind": "LinkedField",
            "name": "metadata",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JourneyCommentUserMetadataDto",
                "kind": "LinkedField",
                "name": "userInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "oid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "threadCommentId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetJourneyCommentListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesGetJourneyCommentListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9572f20cac552e2f7cb3c24552eae40f",
    "id": null,
    "metadata": {},
    "name": "QueriesGetJourneyCommentListQuery",
    "operationKind": "query",
    "text": "query QueriesGetJourneyCommentListQuery(\n  $input: JourneyCommentListQueryInput!\n) {\n  journeyCommentList(input: $input) {\n    items {\n      id\n      content\n      created\n      updated\n      metadata {\n        userInfo {\n          email\n          name\n          oid\n        }\n      }\n      resourceId\n      resourceType\n      status\n      threadCommentId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "558c150015f0aaec368bb7e2c17064ce";

export default node;
