import { useCallback } from 'react'
import { ObjectSchema } from 'yup'
import { UseFormProps, useForm as useFormOriginal, Resolver } from 'react-hook-form'
import { FieldValues, UseFormReturn } from 'react-hook-form/dist/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { UseFormHandleSubmit } from 'react-hook-form/dist/types/form'
export { formArrayCompare } from './utils'

const handleErrors = (errors: any) => {
  console.warn('handleSubmit - form errors: ', errors)
}

export const useForm = <
  TFieldValues extends NonNullable<FieldValues>,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  validationSchema,
  ...props
}: UseFormProps<TFieldValues, TContext> & {
  validationSchema: ObjectSchema<TFieldValues>
}): UseFormReturn<TFieldValues, TContext, TTransformedValues> => {
  const { handleSubmit, ...form } = useFormOriginal<TFieldValues, TContext, TTransformedValues>({
    ...props,
    resolver: yupResolver(validationSchema.required()) as unknown as Resolver<TFieldValues>,
  })

  const handleSubmitWrapper = useCallback<UseFormHandleSubmit<TFieldValues, TTransformedValues>>(
    (onSubmit, onError = handleErrors) => {
      return handleSubmit(onSubmit, onError)
    },
    [handleSubmit],
  )

  return { handleSubmit: handleSubmitWrapper, ...form }
}
